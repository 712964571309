/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "./scss/styles.scss";
@import "cropperjs";

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-Hairline.otf') format("opentype");
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-HairlineItalic.otf') format("opentype");
    font-weight: lighter;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-Thin.otf') format("opentype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-ThinItalic.otf') format("opentype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-ExtraLight.otf') format("opentype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-ExtraLightItalic.otf') format("opentype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-Light.otf') format("opentype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-LightItalic.otf') format("opentype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-Regular.otf') format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-RegularItalic.otf') format("opentype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-Book.otf') format("opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-BookItalic.otf') format("opentype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-SemiBold.otf') format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-SemiBoldItalic.otf') format("opentype");
    font-weight: 600;
    font-style: italic;
}


@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-Bold.otf') format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-BoldItalic.otf') format("opentype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-Heavy.otf') format("opentype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-HeavyItalic.otf') format("opentype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-Black.otf') format("opentype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('assets/fonts/Mont-BlackItalic.otf') format("opentype");
    font-weight: 900;
    font-style: italic;
}

.app-input {
    border: 1px solid #8d949b;
    border-radius: 5px;
    min-height: 50px !important;
    margin-top: 5px;
    color: #000;
    font-size: 12px;
    --highlight-color: #8d949b !important;



    .native-input {
        padding-left: 20px !important;
        padding-right: 45px !important;
    }

    &.has-focus {
        .native-input {
            outline: none;
            -webkit-appearance: none;
            box-shadow: none !important;
            border: none;
        }
    }
}

.chooseProfilePictureModal {
    --width: 100%;
    --height: 100%;
    --border-radius: 40px 40px 0 0;
    padding-top: 65vh;
}
.image-cropper{
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
}

.notificationModal {
    --width: 100%;
    --height: 100%;
    --border-radius: 40px 40px 0 0;
    padding-top: 130px;
    // backdrop-filter: blur(1px);
    // --background: rgba(255, 255, 255, 0.9);
    // background-color: rgba(0, 0, 0, 0.5);
    // background: transparent;
    // background: rgba(255, 255, 255, 0.7)
    // backdrop-filter: blur(10px);
}

.uploadImgModal {
    --max-width: 80%;
    --width: auto;
    --height: auto;
    --border-radius: 20px;
}

.uploadCredentialModal {
    --width: 70%;
    --height: 80%;
    --border-radius: 25px;
}

.wideModal {
    --width: 80%;
    --height: 70%;
    --border-radius: 20px;
}
.autoWide{
    --width: auto;
    --border-radius: 20px;
}

.autoHeightModal {
    --width: 80%;
    --height: auto;
    --border-radius: 20px;

    &.autoWide{
        --width: auto;
        --max-width: 80%;
    }
}

.submitForReviewModal {
    --width: 80%;
    --height: 60%;
}

.expiryModal {
    --height: auto;
    --width: 300px;
    --max-width: 80%;
    --border-radius: 20px;
}

.btn-round {
    --border-radius: 50px;
}

#date-picker {
    position: relative;
    --background-rgb: transparent;
    --background: transparent;
    --wheel-highlight-background: #ededed;
    display: inline-flex;

    &.datetime-size-fixed {
        min-width: auto;
    }

    &::part(wheel-item){
        margin: 10px auto;
        width: 50px;
        text-overflow: unset;
        font-size: 14px;
        text-align: center;
        white-space: pre-wrap;
    }

    &::part(wheel-item active){
        font-size: 16px;
        font-weight: 700;
        color: var(--cui-dark);
    }

    &::before {
        content: " ";
        position: absolute;
        height: 100%;
        width: 15px;
        background: #fff;
        left: 34%;
        z-index: 9;
    }

    &::after {
        content: " ";
        position: absolute;
        height: 100%;
        width: 15px;
        background: #fff;
        left: 61%;
        z-index: 9;
    }

}

ion-button {
    text-transform: none;
}

ion-modal.modal-default.show-modal ~ ion-modal.modal-default {
    --backdrop-opacity: 0.4;
}

.text-danger {
    color: red;
}

@media screen and  (min-width: 766px) {
    #date-picker, .expiryModal {
        --width: 600px;
    
        &::part(wheel-item){
            width: 150px;
        }
    
        &::part(wheel-item active){
            width: 150px;
        }
    }
}

.welcome-modal{
    --width: 70vw;
    zoom: 70%;
    &::part(content) {
        border-radius: 20px;
    }
}

@media screen and (max-width: 767px) {
    .welcome-modal{
        --width: 80%;
    }
}
