:root {
    /** facebook **/
    --ion-color-facebook: #3B5998;
    --ion-color-facebook-rgb: 56, 128, 255;
    --ion-color-facebook-contrast: #ffffff;
    --ion-color-facebook-contrast-rgb: 255, 255, 255;
    --ion-color-facebook-shade: #3B5998;
    --ion-color-facebook-tint: #4c8dff;

    /** decline **/
    --ion-color-decline: #1D2C3A;
    --ion-color-decline-rgb: 56, 128, 255;
    --ion-color-decline-contrast: #ffffff;
    --ion-color-decline-contrast-rgb: 255, 255, 255;
    --ion-color-decline-shade: #1D2C3A;
    --ion-color-decline-tint: #383a3e;


    /** basic **/
    // --ion-color-basic: #0099FF;
    // --ion-color-basic-rgb: 56, 128, 255;
    // --ion-color-basic-contrast: #ffffff;
    // --ion-color-basic-contrast-rgb: 255, 255, 255;
    // --ion-color-basic-shade: #3171e0;
    // --ion-color-basic-tint: #0099FF;

    /** basic **/
    --ion-color-basic: #ffffff;
    --ion-color-basic-rgb: 56, 128, 255;
    --ion-color-basic-contrast: #000;
    --ion-color-basic-contrast-rgb: 255, 255, 255;
    --ion-color-basic-shade: #fffffff1;
    --ion-color-basic-tint: #fffffff1;

    --ion-color-primary: #e4140d;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #e4140de5;
    --ion-color-primary-tint: #e4140de5;

    /** basic-dark **/
    --ion-color-basic-dark: #1D2C3A;
    --ion-color-basic-dark-rgb: 29, 44, 58;
    --ion-color-basic-dark-contrast: #ffffff;
    --ion-color-basic-dark-contrast-rgb: 255, 255, 255;
    --ion-color-basic-dark-shade: #202C3A;
    --ion-color-basic-dark-tint: #202C3A;
}

.ion-color-facebook {
    --ion-color-base: var(--ion-color-facebook) !important;
    --ion-color-base-rgb: var(--ion-color-facebook-rgb) !important;
    --ion-color-contrast: var(--ion-color-facebook-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-facebook-shade) !important;
    --ion-color-tint: var(--ion-color-facebook-tint) !important;
}

.ion-color-decline {
    --ion-color-base: var(--ion-color-decline) !important;
    --ion-color-base-rgb: var(--ion-color-decline-rgb) !important;
    --ion-color-contrast: var(--ion-color-decline-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-decline-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-decline-shade) !important;
    --ion-color-tint: var(--ion-color-decline-tint) !important;
}

.ion-color-basic-dark {
    --ion-color-base: var(--ion-color-basic-dark) !important;
    --ion-color-base-rgb: var(--ion-color-basic-dark-rgb) !important;
    --ion-color-contrast: var(--ion-color-basic-dark-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-basic-dark-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-basic-dark-shade) !important;
    --ion-color-tint: var(--ion-color-basic-dark-tint) !important;
}

.ion-color-basic {
    --ion-color-base: var(--ion-color-basic) !important;
    --ion-color-base-rgb: var(--ion-color-basic-rgb) !important;
    --ion-color-contrast: var(--ion-color-basic-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-basic-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-basic-shade) !important;
    --ion-color-tint: var(--ion-color-basic-tint) !important;
}